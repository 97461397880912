import { forwardRef, useMemo } from 'react';
import type { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrency } from '~/hooks/use-currency';
import { cn } from '~/libs/utils';

function useMoney(amount: string | number) {
  const { i18n } = useTranslation();
  const currency = useCurrency();
  return useMemo(() => {
    const number: number = typeof amount === 'string' ? Number(amount) : amount;
    if (Number.isNaN(number)) return amount;
    return number.toLocaleString(i18n.language, {
      style: 'currency',
      compactDisplay: 'long',
      currency: currency.symbol ?? 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, [amount, i18n.language, currency.symbol]);
}

interface MoneyProps {
  amount: string | number;
  className?: string;
}

export const Money = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement> & Readonly<MoneyProps>>(
  ({ amount, className, ...props }, ref) => {
    return (
      <span ref={ref} className={cn('slashed-zero lining-nums tabular-nums', className)} {...props}>
        {useMoney(amount)}
      </span>
    );
  }
);
